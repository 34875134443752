import React from 'react';

function IsLoading (){
    
    return (
        <div class="loading-body">
            <div class="loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            </div>
        </div>
        
    );
  }
  
  export default IsLoading;