import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from "@auth0/auth0-react";

ReactDOM.render(
  
  <React.StrictMode>
    <Auth0Provider
    domain="inieam.us.auth0.com"
    clientId="z1FEexjVrhoj4EKPWjvi1wli85w1Oo3F"
    redirectUri={window.location.origin}
    audience='https://app.inieam.org.mx'
    scope="create:customer read:customer edit:customer delete:customer create:receipt read:receipt edit:receipt delete:receipt"
    >
    <App />
  </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
